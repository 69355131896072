@tailwind base;
@tailwind components;
@tailwind utilities;


@layer base {
    .noAuto:-webkit-autofill,
    .noAuto:-webkit-autofill:hover,
    .noAuto:-webkit-autofill:focus,
    .noAuto:-webkit-autofill:active{
        -webkit-box-shadow: 0 0 0 30px white inset;
        outline: none;
    }
}

@layer components {
    .transi {
        transition: all 0.3s ease-out;
    }
}